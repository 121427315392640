/*eslint-disable */
// import UserBillDetailsModel from '../../Model/UserBillDetails'
import request from '../../Utils/curl'

let UserBillDetails = {
  /**
   * UserBillDetailsList
   */
  async UserBillDetailsList (context, userId=null) {
    try{
      let post_data = new FormData();

      if (userId) {
        post_data.append('user_id', userId);
      }

      return await request.curl(context, "UserBillDetails_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at UserBillDetailsList() and Exception:',err.message)
    }
  },

  /**
   * UserBillDetailsView
   */
  async UserBillDetailsView(context, UserBillDetailsId) {
    try {
      
      let post_data = new FormData();
      post_data.append('bill_id', UserBillDetailsId);
      return await request.curl(context, "UserBillDetails_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at UserBillDetailsView() and Exception:",err.message)
    }
    
  },

  /**
   * UserBillDetailsAdd
   */
  async UserBillDetailsAdd (context, UserBillDetailsObj) {
    try{
    let post_data = new FormData();
    
    for (let key in UserBillDetailsObj) {
      if ((UserBillDetailsObj[key] && typeof UserBillDetailsObj[key] !== 'number') || typeof UserBillDetailsObj[key] === 'number') {
        post_data.append(key, UserBillDetailsObj[key]);
      }
    }

    return await request.curl(context, "UserBillDetails_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at UserBillDetailsAdd() and Exception:',err.message)
    }
  },

  /**
   * UserBillDetailsEdit
   */
  async UserBillDetailsEdit (context, UserBillDetailsObj) {
    try{
    let post_data = new FormData();
    
    for (let key in UserBillDetailsObj) {
      if ((UserBillDetailsObj[key] && typeof UserBillDetailsObj[key] !== 'number') || typeof UserBillDetailsObj[key] === 'number') {
        post_data.append(key, UserBillDetailsObj[key]);
      }
    }

    return await request.curl(context, "UserBillDetails_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at UserBillDetailsEdit() and Exception:',err.message)
    }
  },

  /**
   * UserBillDetailsDelete
   */
  async UserBillDetailsDelete (context, UserBillDetailsId) {
    try{
    let post_data = new FormData();
    
    post_data.append('bill_id', UserBillDetailsId);

    return await request.curl(context, "UserBillDetails_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at UserBillDetailsDelete() and Exception:',err.message)
    }
  }
}

export {
  UserBillDetails
}
